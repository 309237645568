<template>
  <div>
    <div :class="{ clickable: this.site.selectable }" @click="setSite">
      <span style="font-size: 18px" class="mb-1">{{ site.displayname }}</span>
      <div>{{ site.shortname }}</div>
      <div v-if="site.team">{{ site.team.displayname }}</div>
      <div v-if="site.ip_subnet">{{ site.ip_subnet }}</div>
      <div v-if="site.service_version">{{ site.service_version.version }}
        <b-icon-info-circle-fill class="ml-2" :id="'tt-info-' + site.id"/>
        <b-tooltip :target="'tt-info-' + site.id" triggers="hover">Dienstversionsnummer</b-tooltip>
      </div>
    </div>
    <TransitionExpand>
      <div v-if="expand">
        <b-form @submit.stop.prevent="" class="mt-2">
          <b-form-textarea v-model="note" placeholder="Notizfeld..." rows="6"/>
        </b-form>
        <b-row class="my-2">
          <b-col cols="auto">
            <b-button variant="primary" size="sm" @click="saveNote">
              <b-spinner v-show="loadingSaveButton" small/>
              Speichern
            </b-button>
          </b-col>
        </b-row>
      </div>
    </TransitionExpand>
    <b-row>
      <b-col v-if="expand" class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
        <b-icon-chevron-up :variant="chevronVariant" class="clickable" scale="1.5"/>
      </b-col>
      <b-col v-else class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
        <b-icon-chevron-down :variant="chevronVariant" class="clickable" scale="1.5"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";
import TransitionExpand from "@/components/utility/TransitionExpand.vue";

export default {
  name: "Site",
  props: ['site'],
  components: {TransitionExpand
  },
  data() {
    return {
      note: this.site.note,
      expand: false,
      loadingSaveButton: false
    }
  },
  methods: {
    ...mapActions(['selectSite']),
    setSite() {
      if(!this.site.selectable) { return }
      this.selectSite(this.site.shortname)
      if(this.$route.query.site && this.$route.query.site !== this.siteSelected.shortname) {
        this.$router.push({name: this.$route.name, query: {site: this.siteSelected.shortname}})
      }
    },
    async saveNote() {
      this.loadingSaveButton = true
      let data = {
        note: this.note
      }
      await putRequest('sites/note/' + this.site.shortname, data, this, 'Erfolgreich gespeichert!')
          .then(() => {
            this.loadingSaveButton = false
            this.$emit('refresh')
          })
    },
    showDetails() {
      this.expand = !this.expand;
    }
  },
  computed: {
    ...mapGetters(['siteSelected']),
    ...mapGetters(['userThemeLight']),
    chevronVariant() {
      if(this.userThemeLight) {
        return 'dark'
      }
      return 'light'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Tiles.scss';
</style>
