<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="3"/>
        <b-col cols="6">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="3">
                <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Standort..."/>
              </b-col>
            </b-row>
          </SubHeader>
          <b-tabs v-model="tabIndex" content-class="mt-n2" class="mt-2 tab-title" fill>
            <b-tab title="Standorte" active>
              <SiteTab :siteList="siteList" @refresh="getSites"/>
            </b-tab>
            <b-tab title="Teams">
              <TeamTab :teamList="teamList"/>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col cols="3"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import SiteTab from "@/components/lists/siteList/SiteTab";
import TeamTab from "@/components/lists/siteList/TeamTab";
import ScrollToTop from "@/components/utility/ScrollToTop";
import SubHeader from "@/components/structure/SubHeader";

export default {
  name: "SiteList",
  components: {
    SubHeader,
    TeamTab,
    SiteTab,
    ScrollToTop
  },
  data() {
    return {
      search: '',
      teams: null,
      sites: null,
      tabIndex: 0,
    }
  },
  watch: {
    tabIndex: function(val) {
      this.search = '';
      if(val === 0) {
        this.placeholder = 'Standort...'
      }
      else {
        this.placeholder = 'Team...'
      }
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getSites() {
      this.setLoadingState(true)
      await getRequest('sites', null, this)
          .then((response) => {
            this.sites = response.data
          })
      this.setLoadingState(false)
    },
    async getTeams() {
      this.setLoadingState(true)
      await getRequest('teams', null, this)
          .then((response) => {
            this.teams = response.data
          })
      this.setLoadingState(false)
    }
  },
  computed: {
    siteList() {
      return _.filter(this.sites, (entry) => {
        if (entry.displayname.toLowerCase().includes(this.search.toLowerCase())) {
          return true
        }
      })
    },
    teamList() {
      return _.filter(this.teams, (entry) => {
        if (entry.displayname.toLowerCase().includes(this.search.toLowerCase())) {
          return true
        }
      })
    },
  },
  created() {
    this.getSites();
    this.getTeams();
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/Tabs.scss';
</style>
