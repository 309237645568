<template>
  <b-list-group :class="tabClasses" style="font-size: 14px">
    <b-row cols="1">
      <b-col>
        <b-list-group-item v-for="site in siteList" :key="site.id" class="list-row flex-fill">
          <Site :site="site" @refresh="refresh"/>
        </b-list-group-item>
      </b-col>
    </b-row>
  </b-list-group>
</template>

<script>
import {mapGetters} from "vuex";
import Site from "@/components/lists/siteList/Site";

export default {
  name: "SiteTab",
  props: ['siteList'],
  components: {
    Site
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    tabClasses() {
      if(this.userThemeLight) {
        return ['mt-2']
      }
      return ['text-white', 'mt-2']
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
