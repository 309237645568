<template>
  <div>
    <span style="font-size: 18px" class="mb-1">{{ team.displayname }}</span>
    <TransitionExpand>
      <div v-if="expand">
        <div v-for="site in team.sites" :key="site.id" class="ml-3">{{ site.displayname }}</div>
      </div>
    </TransitionExpand>
    <b-row>
      <b-col v-if="expand" class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
        <b-icon-chevron-up :variant="chevronVariant" class="clickable" scale="1.5"/>
      </b-col>
      <b-col v-else class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
        <b-icon-chevron-down :variant="chevronVariant" class="clickable" scale="1.5"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TransitionExpand from "@/components/utility/TransitionExpand";

export default {
  name: "Team",
  props: ['team'],
  components: {
    TransitionExpand
  },
  data() {
    return {
      expand: false
    }
  },
  methods: {
    showDetails() {
      this.expand = !this.expand;
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    chevronVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'light'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Tiles.scss';
</style>
